import Cookies from 'js-cookie';

function getInitialCookiesState () {
  const cookie = Cookies.get('gdpr');

  if (cookie) {
    try {
      const json = JSON.parse(cookie);
      if (json.hasOwnProperty('advertising')) {
        return json;
      }
      Cookies.remove('gdpr');
    } catch (e) {
      // do nothing, fall through
    }
  }

  return {
    necessary: true,
    performance: false,
    personalisation: false,
    advertising: false,
    advertisingUserData: false,
    advertisingPersonalisation: false,
    saved: false,
    expanded: false
  }
}

export function cookiesSettings(state = getInitialCookiesState(), action) {
  switch (action.type) {
    case 'SAVE_COOKIES_SETTINGS':
      return { ...state, ...action.settings };
    case 'UNHIDE_MODAL':
      return { ...state, saved: false };
    case 'EXPAND_SETTINGS':
      return { ...state, expanded: true }
    default:
      return state;
  }
}

export function pubSearch(state = {
  byName: [],
  byLocation: [],
  query: {}
}, action) {
  switch (action.type) {
    case 'SAVE_DATA_BY_NAME':
      return { ...state, byName: action.data };
    case 'SAVE_DATA_BY_LOCATION':
      return { ...state, byLocation: action.data };
    case 'SAVE_QUERY':
      return { ...state, query: action.query }
    default:
      return state;
  }
}
